import { useStatefulRef as r } from "@bedrock-layout/use-stateful-ref";
import s, { useRef as c } from "react";
function o(t, u = { isStateful: !0 }) {
  const n = r(null), f = c(null), e = u.isStateful ? n : f;
  return s.useEffect(() => {
    !t || (typeof t == "function" ? t(e.current) : t.current = e.current);
  }), e;
}
export {
  o as default,
  o as useForwardedRef
};
