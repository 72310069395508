import u from "react";
function s(c = null) {
  let [e, f] = u.useState(c);
  const { current: r } = u.useRef({
    current: e
  });
  return Object.defineProperty(r, "current", {
    get: () => e,
    set: (t) => {
      Object.is(e, t) || (e = t, f(t));
    }
  }), r;
}
export {
  s as default,
  s as useStatefulRef
};
